/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState, useEffect} from 'react'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {ProgressBarCircle} from './progressBarCircle'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useIntl} from 'react-intl'
// import {ProgressBarLine} from './progressBarLine'
// import {date} from 'yup'
// import {AllCourses} from '../partials/allCourses'

type Props = {
  title: String
  subtitle: String
  hour: Number
  lesson: Number
  primary?: Boolean
  primaryText?: String
  progressValue?: number
  remainingMin?: number
  imgUrl?: number
  basTarih?: number
  id: number
  allCourses?: boolean
  isCompleted?: boolean
  completedText?: string
}

const CourseBox: FC<Props> = ({
  title,
  subtitle,
  hour,
  lesson,
  primary,
  primaryText,
  progressValue,
  remainingMin,
  imgUrl,
  basTarih,
  id,
  allCourses,
  isCompleted,
  completedText,
}) => {
  const [isStarted, setStarted] = useState(false)
  const [buttonText, setButtonText] = useState('')
  const intl = useIntl()

  const isStartedCourse = () => {
    if (basTarih) {
      const basTarihDate = new Date(basTarih)
      const now = new Date(1)
      if (basTarihDate < now) {
        setStarted(true)
      } else {
        setButtonText(
          intl.formatMessage({id: 'POPLEADS.AVAILABLE'}) +
            ' ' +
            moment(basTarihDate).format('MMM DD, YYYY')
        )
      }
    }
  }

  useEffect(() => {
    isStartedCourse()
  })

  return (
    <div className='allCoursesGridItem'>
      <Link
        to={
          primary && primaryText
            ? allCourses
              ? '/courses/detail/' + id
              : '/my-courses/detail/' + id
            : '#'
        }
      >
        <div
          className='allCoursesGridItemImage'
          style={{
            backgroundImage:
              'url("' +
              (imgUrl ? imgUrl : 'https://www.leoron.com/wp-content/uploads/CFM-2.jpg') +
              '")',
          }}
        ></div>
      </Link>
      <div className='allCoursesGridInfo'>
        <div className='allCoursesGridInfo_Time d-flex align-items-center'>
          <KTSVG path='/media/svg/popleads/clock.svg' className='svg-icon-1 hour-icon' />
          {hour ? (
            <span>
              {hour} {intl.formatMessage({id: 'POPLEADS.HOUR'})}
              {(hour > 1 || isNaN(+hour)) && 's'}
            </span>
          ) : (
            <span> - {intl.formatMessage({id: 'POPLEADS.HOUR'})}</span>
          )}
        </div>
        <div className='allCoursesGridInfo_Length d-flex align-items-center'>
          <KTSVG path='/media/svg/popleads/book.svg' className='svg-icon-1 hour-icon' />
          {lesson ? (
            <span>
              {lesson} {intl.formatMessage({id: 'POPLEADS.LESSON'})}
              {lesson > 1 && 's'}
            </span>
          ) : (
            <span>- {intl.formatMessage({id: 'POPLEADS.LESSON'})} </span>
          )}
        </div>
      </div>
      <div className='allCoursesGridItemSubtitle'>{subtitle}</div>
      <Link
        to={
          primary && primaryText
            ? allCourses
              ? '/courses/detail/' + id
              : '/my-courses/detail/' + id
            : '#'
        }
      >
        <div className='allCoursesGridItemTitle'>{title} </div>
      </Link>
      {(progressValue || progressValue === 0 || remainingMin) && !isCompleted && (
        <div className='courseProgress d-flex justify-content-center'>
          <ProgressBarCircle value={progressValue ? progressValue : 0} />
          {/*<ProgressBarLine value={progressValue ? progressValue : 0} remainingMin={remainingMin ? remainingMin : 0} /> */}
        </div>
      )}
      <Link
        to={
          primary && primaryText
            ? allCourses
              ? '/courses/detail/' + id
              : '/my-courses/detail/' + id
            : '#'
        }
      >
        <button
          className={clsx(
            'allCoursesGridItemButton',
            primary && 'primary',
            isCompleted && 'completed'
          )}
        >
          {isCompleted ? completedText : primary ? primaryText : buttonText}
        </button>
      </Link>
    </div>
  )
}

export {CourseBox}
