/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const Landing: FC = () => {
  const intl = useIntl()

  // const html = document.getElementsByTagName("html")[0]
  // const isRTL = html.getAttribute("dir") === "rtl"

  return (
    <>
      <div className='landing px-4 py-10 p-lg-0 justify-content-between align-items-center'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className={'d-flex flex-column flex-lg-row align-items-start'} id='kt_post'>
            <div
              className='col d-flex flex-column justify-content-center align-self-center m-lg-20'
            >
              <h1 className='mx-auto mx-lg-0'>
                <div className='d-flex'>
                  <span className='wel-text me-3 me-lg-5'>
                    {intl.formatMessage({id: 'POPLEADS.WELCOME_TO'})}
                    <br />
                    {intl.formatMessage({id: 'POPLEADS.LEARNING_PLATFORM'})}
                  </span>
                </div>
              </h1>
              <p className='login-gray-message mt-5 text-center text-lg-start'>
                {intl.formatMessage({id: 'POPLEADS.LEARNING_PLATFORM_HOME'})}
              </p>
            </div>
            <div className='d-flex justify-content-end'>
              <img
                id='illusRight'
                alt=''
                src={toAbsoluteUrl('/media/stock/masa-home.png')}
                className='mt-5 mt-lg-0'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Landing}
