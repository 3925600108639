import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'

type Props = {
  basename: string
}

const root = document.documentElement;
root?.style.setProperty("--background-color", "#FBFCFD");
root?.style.setProperty("--primary-text-color", "#6787A9");
root?.style.setProperty("--landing-text-color", "#FFFFFF");
root?.style.setProperty("--landing-secondary-text-color", "#023770");
root?.style.setProperty("--all-courses-section-title-color", "#FBFCFD");
root?.style.setProperty("--all-courses-section-title-background-color", "#023770");
root?.style.setProperty("--all-courses-grid-item-background-color", "#FFFFFF");
root?.style.setProperty("--all-courses-info-color", "#6787A9");
root?.style.setProperty("--all-courses-item-subtitle-color", "#6787A9");
root?.style.setProperty("--all-courses-item-title-color", "#023770");
root?.style.setProperty("--all-courses-item-button-background-color", "#E5EBF0");
root?.style.setProperty("--all-courses-item-button-primary-background-color", "#023770");
root?.style.setProperty("--all-courses-item-button-color", "#6787A9");
root?.style.setProperty("--all-courses-item-button-primary-color", "#FBFCFD");
root?.style.setProperty("--footer-background-color", "#E5EBF0");
root?.style.setProperty("--footer-color", "#6787A9");
root?.style.setProperty("--scrollTop-background-color", "#6787A9");
root?.style.setProperty("--scrollTop-color", "#FFFFFF");
root?.style.setProperty("--selected-menu-item-border-color", "#F39200");
root?.style.setProperty("--selected-navbar-item-border-color", "#F4B249");
root?.style.setProperty("--header-menu-item-color", "#FFFFFF");
root?.style.setProperty("--header-selected-menu-item-color", "#FFFFFF");
root?.style.setProperty("--header-dropdown-menu-item-color", "#023770");
root?.style.setProperty("--myCourse-subjects-background-color", "#6787A9");
root?.style.setProperty("--myCourse-subjects-items-background-color", "#526C87");
root?.style.setProperty("--myCourse-subjects-color", "#FBFCFD");
root?.style.setProperty("--primary-button-background-color", "#F39200");
root?.style.setProperty("--navbar-myCourses-background-color", "#023770");
root?.style.setProperty("--login-box-color", "#FFFFFF");
root?.style.setProperty("--login-welcome-message", "#B5BEC3");   //KULLANILMIYOR
root?.style.setProperty("--navbar-myCourses-color", "rgba(251,252,253,0.65)");
root?.style.setProperty("--navbar-myCourses-selected-color", "rgb(251,252,253)");
root?.style.setProperty("--faq-background-color", "#FFFFFF");
root?.style.setProperty("--faq-button-color", "#F39200");
root?.style.setProperty("--faq-text-color", "#6787A9");
root?.style.setProperty("--certificate-button-background-color", "#FFFFFF");
root?.style.setProperty("--certificate-button-color", "#023770");
root?.style.setProperty("--no-course-text-color", "#6787A9");
root?.style.setProperty("--searchBox-inputs", "#FFFFFF");
root?.style.setProperty("--Icon_feather-play", "#F39200");
root?.style.setProperty("--scrollbar-color", "#F39200");
root?.style.setProperty("--input-border-color", "#E5EBF0");
root?.style.setProperty("--input-invalid-border-color", "#F39200");
root?.style.setProperty("--login-box-shadow-color", "#0237701D");
root?.style.setProperty("--explore-button-bg-color", "#023770");
root?.style.setProperty("--explore-button-text-color", "#FBFCFD");
root?.style.setProperty("--progress-empty-color", "#E5EBF0");
root?.style.setProperty("--progress-fill-color", "#6787A9");
root?.style.setProperty("--errorpage-icon-color", "#F39200");
root?.style.setProperty("--errorpage-title-color", "#023770");
root?.style.setProperty("--errorpage-text-color", "#6787A9");
root?.style.setProperty("--errorpage-btn-bg-color", "#023770");
root?.style.setProperty("--errorpage-btn-text-color", "#FBFCFD");


// BELOW ARE NOT USED RIGHT NOW
root?.style.setProperty("--all-courses-title-color", "#B5BEC3");
root?.style.setProperty("--all-courses-section-title-triangle-color", "#F39200");
root?.style.setProperty("--trainer-container-background-color", "#4C5B69");
root?.style.setProperty("--trainer-container-color", "#FFFFFF");
root?.style.setProperty("--add-discussion-button-background-color", "#4C5B69");
root?.style.setProperty("--add-discussion-button-color", "#B5BEC3");


const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
