/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {post} from '../networking/requestService'
import {MyCoursesNoCourse} from '../partials/myCoursesNoCourse'
import {MyCoursesWithCourses} from '../partials/myCoursesWithCourses'
import {Error} from '../partials/error'
import {Loading} from '../partials/loading'
import {Content} from '../../../_metronic/layout/components/Content'
import {useLayout} from '../../../_metronic/layout/core'
import clsx from 'clsx'

const MyCourses: FC = () => {
  const [courses, setCourses] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const requestObj = {
      fn: 'getProfileCampaigns',
    }
    post(requestObj)
      .then(({data: {campaignCurrentList, campaignHistoryList}}) => {
        setCourses({campaignCurrentList, campaignHistoryList})
        setLoading(false)
      })
      .catch((err) => {
        setError({text: err.response.data.text, code: err.response.status})
        setLoading(false)
      })
  }, [])

  const {classes} = useLayout()

  return (
    <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
      <div
        className={clsx(
          'd-flex flex-column-fluid align-items-start',
          classes.contentContainer.join(' ')
        )}
        id='kt_post'
      >
        <Content>
          {' '}
          {loading ? (
            <Loading />
          ) : error ? (
            <Error text={error.text} code={error.code} />
          ) : courses.campaignCurrentList.length === 0 &&
            courses.campaignHistoryList.length === 0 ? (
            <MyCoursesNoCourse />
          ) : (
            <MyCoursesWithCourses
              campaignCurrentList={courses.campaignCurrentList}
              campaignHistoryList={courses.campaignHistoryList}
            />
          )}
        </Content>
      </div>
    </div>
  )
}

export {MyCourses}
